import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Typography,
  Box,
  Switch,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Arrow } from './arrowDown.svg';
import { ReactComponent as Team } from './team-icon.svg';
import CompanyIcon from './company.png';
import Questions from './questions.png';
import './styles.css';
import CloseIcon from '@mui/icons-material/Close';
import { mutationFreeTrialUpgradeCompany } from '../../../services/company_services';
import { useAccount } from '../../../contexts/Account';
import ACSnackbar from '../../ACSnackbar';
import { SnackbarType } from '../../../services/utils/types';
import { useTranslation } from 'react-i18next';
import CallWindow from '../UpgradeCall';
import LoadingLogoSkeleton from '../../SkeletonLoading/loadingLogoSkeleton';
import { sendFSEventOnUpgrade } from '../../../services/FullStory';

const CardPlanCode = (props) => {
  const {
    onClick,
    membership,
    onContinue,
    userSegment,
    setOpenModal,
    price,
    annualChecked,
    month1,
    month2,
    annual1,
    annual2,
    userTier,
    tierMonth1,
    tierMonth2,
    tierAnnual1,
    tierAnnual2,
    handleContinueStep3,
    monthlyUpgrade1Calendar,
    monthlyUpgrade2Calendar,
    annualUpgrade1Calendar,
    annualUpgrade2Calendar,
    closeModal,
  } = props;

  const {
    accountDetails,
    editAccountInformation,
    isFreemium,
    currentPlan,
    type,
  } = useAccount();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);
  const { t } = useTranslation();
  const [openCalendar, setOpenCalendar] = useState(false);
  const location = useLocation();

  const SelectedPlan = () => {
    if (annualChecked) {
      if (membership === 'traditional') {
        return annual2?.recurlyPlanCode;
      } else if (membership === 'crowdsourced') {
        return annual1?.recurlyPlanCode;
      }
    } else {
      if (membership === 'crowdsourced') {
        return month1?.recurlyPlanCode;
      } else if (membership === 'traditional') {
        return month2?.recurlyPlanCode;
      }
    }
    return null;
  };

  const planCodeSelected = SelectedPlan();

  function getSelectedTier() {
    if (annualChecked) {
      if (membership === 'traditional') {
        return tierAnnual2;
      } else if (membership === 'crowdsourced') {
        return tierAnnual1;
      }
    } else {
      if (membership === 'crowdsourced') {
        return tierMonth1;
      } else if (membership === 'traditional') {
        return tierMonth2;
      }
    }
    return null;
  }

  const sendFSEvent = (success, errorMessage = '', error = null) => {
    sendFSEventOnUpgrade({
      accountDetails,
      currentPlan: currentPlan,
      newPlan: {
        recurlyPlanCode: planCodeSelected,
        salesforceValues: {
          tier: getSelectedTier(),
        },
      },
      source: location?.state?.fromBanner ? 'banner' : 'gate',
      type,
      success,
      errorMessage: success ? null : errorMessage,
      error: success ? null : error,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickContinue = () => {
    if (membership === 'crowdsourced') {
      onClick(1);
    } else if (membership === 'traditional') {
      onClick(2);
    }
    onContinue();
  };

  const handleBookACall = () => {
    handleContinueStep3();
  };

  async function upgradeCall() {
    if (membership && !loading) {
      setLoading(true);
      mutationFreeTrialUpgradeCompany({
        COMPANY_ID: accountDetails.id,
        COMPANY_CONTACT_ID: accountDetails.contacts[0].id,
        IS_UPGRADE: planCodeSelected,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.freeTrialUpgradeCompany
          ) {
            const newCompany = response.data.freeTrialUpgradeCompany;
            setSnackMessage(
              'Your account has been upgraded, you now have full access to AdvisoryCloud'
            );
            setSnackbarSuccess(true);
            setLoading(false);
            editAccountInformation(
              {
                freeTrialEndsOn: newCompany.freeTrialEndsOn,
                recurlyPlanCode: newCompany.recurlyPlanCode,
              },
              true
            );
            sendFSEvent(true);
            handleClose();
            if (
              monthlyUpgrade1Calendar ||
              monthlyUpgrade2Calendar ||
              annualUpgrade1Calendar ||
              annualUpgrade2Calendar
            ) {
              handleContinueStep3(true, planCodeSelected);
            } else {
              closeModal();
            }
          }
        })
        .catch((e) => {
          let errorMessage = '';
          try {
            const err = JSON.parse(e.errors[0].message);
            if (err.code === errorCodes.recurlyError) {
              errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_RECURLY');
            } else if (err.code === errorCodes.dynamoError) {
              errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_DYNAMO');
            } else if (err.code === errorCodes.salesforceError) {
              setSnackMessage(
                'Your account has been upgraded, you now have full access to AdvisoryCloud'
              );
              setSnackbarSuccess(true);
              return;
            } else {
              errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
            }
            setLoading(false);
            handleClose();
          } catch (err) {
            errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
            setLoading(false);
            handleClose();
          }
          sendFSEvent(false, errorMessage, e);
          setSnackMessage(errorMessage);
          setSnackbarError(true);
        });
    } else {
      setSnackMessage('Select an upgrade option to continue');
      setSnackbarSuccess(true);
      handleClose();
    }
  }

  const getBorderStyle = () => {
    if (
      (userTier === tierAnnual1 || userTier === tierMonth1) &&
      membership === 'crowdsourced'
    ) {
      return 'none';
    }
    if (
      (userTier === tierAnnual2 || userTier === tierMonth2) &&
      membership === 'traditional'
    ) {
      return 'none';
    }
    return '1px solid #EFF0F6';
  };

  const getBorderColor = () => {
    if (
      (userTier === tierAnnual1 || userTier === tierMonth1) &&
      membership === 'crowdsourced'
    ) {
      return '#3171F6';
    }
    if (
      (userTier === tierAnnual2 || userTier === tierMonth2) &&
      membership === 'traditional'
    ) {
      return '#3171F6';
    }
    return '#C7D0DE';
  };

  const getBorderShadow = () => {
    if (
      (userTier === tierAnnual1 || userTier === tierMonth1) &&
      membership === 'crowdsourced'
    ) {
      return '0 0 5px 0px #3171F6';
    }
    if (
      (userTier === tierAnnual2 || userTier === tierMonth2) &&
      membership === 'traditional'
    ) {
      return '0 0 5px 0px #3171F6';
    }
    return 'none';
  };

  const traditionalSegment =
    userSegment === 'traditional_advisory_board' &&
    membership === 'traditional';
  const crowdsourcedSegment =
    userSegment !== 'traditional_advisory_board' &&
    membership === 'crowdsourced';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => {
          setSnackbarSuccess(false);
        }}
        autoHideDuration={3000}
      />
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarError}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.ERROR}
        onClose={() => {
          setSnackbarError(false);
        }}
        autoHideDuration={6000}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 10,
            width: 300,
            height: 206,
            border: '1px solid #E0E0E0',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent style={{ textAlign: 'center', position: 'relative' }}>
          <IconButton
            disableRipple
            size="small"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginTop: '15px',
              textAlign: 'center',
            }}
          >
            Confirm your upgrade!
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '13px',
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              width: '235px',
              marginTop: '5px',
              textAlign: 'center',
            }}
          >
            Clicking “confirm” will charge your card on file, and upgrade your
            account
          </Typography>

          <button
            disabled={loading}
            variant="contained"
            color="primary"
            style={{
              marginBottom: '10px',
              position: 'relative',
              fontSize: '15px',
              borderRadius: '999px',
              fontWeight: 700,
              width: '168px',
              height: '45px',
              backgroundColor: loading ? '#e2e2e2' : '#3171F6',
              border: 'none',
              color: '#FFFFFF',
              borderColor: 'none',
              fontFamily: 'Poppins',
              outline: 'none',
              cursor: loading ? 'default' : 'pointer',
              marginTop: '20px',
            }}
            onClick={upgradeCall}
          >
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingLogoSkeleton />
              </Box>
            ) : (
              ' Confirm'
            )}
          </button>
        </DialogContent>
      </Dialog>
      <Dialog open={openCalendar} onClose={() => setOpenCalendar(false)}>
        <DialogContent>
          <CallWindow
            meetingId="SOIDIV_marlawilliamscomplimentaryplatformdemo"
            meetingData="marlawilliamscomplimentaryplatformdemo"
            psz="00"
            style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
          />
        </DialogContent>
      </Dialog>
      {annualChecked ? (
        <>
          {userTier === tierAnnual1 && membership === 'crowdsourced' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xxs: '-10px', xs: '-34px', sm: '-40px' },
                marginBottom: { xxs: '10px', sm: '17px' },
              }}
            >
              <Arrow />
              <Typography
                sx={{
                  color: '#646D7A',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.5px',
                  textAlign: 'center',
                  marginLeft: '5px',
                }}
              >
                Your current plan
              </Typography>
            </Box>
          )}
          {userTier === tierAnnual2 && membership === 'traditional' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xxs: '-10px', xs: '-34px', sm: '-40px' },
                marginBottom: { xxs: '10px', sm: '17px' },
              }}
            >
              <Arrow />
              <Typography
                sx={{
                  color: '#646D7A',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.5px',
                  textAlign: 'center',
                  marginLeft: '5px',
                }}
              >
                Your current plan
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          {userTier === tierMonth1 && membership === 'crowdsourced' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xxs: '-10px', xs: '-34px', sm: '-40px' },
                marginBottom: { xxs: '10px', sm: '17px' },
              }}
            >
              <Arrow />
              <Typography
                sx={{
                  color: '#646D7A',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.5px',
                  textAlign: 'center',
                  marginLeft: '5px',
                }}
              >
                Your current plan
              </Typography>
            </Box>
          )}
          {userTier === tierMonth2 && membership === 'traditional' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: { xxs: '-10px', xs: '-34px', sm: '-40px' },
                marginBottom: { xxs: '10px', sm: '17px' },
              }}
            >
              <Arrow />
              <Typography
                sx={{
                  color: '#646D7A',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.5px',
                  textAlign: 'center',
                  marginLeft: '5px',
                }}
              >
                Your current plan
              </Typography>
            </Box>
          )}
        </>
      )}
      <Box
        id={
          membership === 'crowdsourced'
            ? 'upgradeOptionOne'
            : membership === 'traditional'
            ? 'upgradeOptionTwo'
            : 'bookCallTile'
        }
        sx={{
          width: '260px',
          height: '360px',
          border: getBorderStyle(),
          borderColor: getBorderColor(),
          boxShadow: getBorderShadow(),
          borderRadius: '15px',
          zIndex: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              marginTop: '20px',
            }}
          >
            {membership === 'crowdsourced' && <Team alt="company" style={{}} />}
            {membership === 'traditional' && (
              <img
                src={CompanyIcon}
                alt="company"
                style={{ width: '52px', height: '52px' }}
              />
            )}
            {membership === 'current' && (
              <img
                src={Questions}
                alt="Questions"
                style={{ width: '52px', height: '52px' }}
              />
            )}
          </Box>

          <Typography
            sx={{
              color: '#232B35',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 800,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginTop: '15px',
              marginBottom: '0px',
              textAlign: 'center',
            }}
          >
            {membership === 'current' && 'Have questions?'}
            {annualChecked ? (
              <>
                {membership === 'crowdsourced' && tierAnnual1}
                {membership === 'traditional' && tierAnnual2}
              </>
            ) : (
              <>
                {membership === 'crowdsourced' && tierMonth1}
                {membership === 'traditional' && tierMonth2}
              </>
            )}
          </Typography>

          <Typography
            sx={{
              color: '#232B35',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              marginBottom: membership === 'current' ? '10px' : '20px',
              textAlign: 'center',
              padding: '10px',
            }}
          >
            {membership === 'current' &&
              'Work with our team to figure out the best fit for any current challenges and business needs'}
            {membership === 'traditional' &&
              'Assemble a hand-selected advisory board of up to 12 advisors sourced and vetted by our team'}
            {membership === 'crowdsourced' &&
              'Assemble an advisory board where any advisor in our private network can join and give you their input'}
          </Typography>
          {membership === 'current' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '21px',
                }}
              >
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '22px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    textAlign: 'center',
                  }}
                >
                  FREE
                </Typography>
              </Box>
            </>
          )}

          {membership === 'traditional' && (
            <Box className="price-container">
              <Box
                className={`price-box ${annualChecked ? 'annual-checked' : ''}`}
              >
                <Box
                  id="upgradeOptionTwoMonthlyPrice"
                  className="price-content"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      color: '#232B35',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      textAlign: 'center',
                    }}
                  >
                    {annualChecked ? `$${Math.round(price / 12)}` : `$${price}`}

                    <Typography
                      sx={{
                        fontSize: '10px',
                        marginLeft: '5px',
                      }}
                    >
                      /mo
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {annualChecked && `billed annually $${price}`}
                  </Typography>
                </Box>
                <Box
                  id="upgradeOptionTwoAnnualPrice"
                  className="price-content price-content-back"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      color: '#232B35',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      textAlign: 'center',
                    }}
                  >
                    ${Math.round(price / 12)}
                    <Typography
                      sx={{
                        fontSize: '10px',
                        marginLeft: '5px',
                      }}
                    >
                      /mo
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {annualChecked && `billed annually $${price}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {membership === 'crowdsourced' && (
            <Box className="price-container">
              <Box
                className={`price-box ${annualChecked ? 'annual-checked' : ''}`}
              >
                <Box
                  id="upgradeOptionOneMonthlyPrice"
                  className="price-content"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      color: '#232B35',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      textAlign: 'center',
                    }}
                  >
                    {annualChecked ? `$${Math.round(price / 12)}` : `$${price}`}
                    <Typography
                      sx={{
                        fontSize: '10px',
                        marginLeft: '5px',
                      }}
                    >
                      /mo
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {annualChecked && `billed annually $${price}`}
                  </Typography>
                </Box>
                <Box
                  id="upgradeOptionOneAnnualPrice"
                  className="price-content price-content-back"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      color: '#232B35',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      textAlign: 'center',
                    }}
                  >
                    ${Math.round(price / 12)}
                    <Typography
                      sx={{
                        fontSize: '10px',
                        marginLeft: '5px',
                      }}
                    >
                      /mo
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {annualChecked && `billed annually $${price}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {membership === 'traditional' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                  marginTop: '37px',
                }}
              >
                <button
                  id="upgradeOptionTwoUpgradeNow"
                  style={{
                    marginBottom: '10px',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#D7F1FD',
                    border: '1px solid #4BC6FF',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Upgrade Now
                </button>
                <Typography
                  id="upgradeOptionTwoLearnMore"
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.5px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={handleClickContinue}
                >
                  Learn More
                </Typography>
              </Box>
            </>
          )}
          {membership === 'crowdsourced' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                  marginTop: '37px',
                }}
              >
                <button
                  id="upgradeOptionOneUpgradeNow"
                  style={{
                    marginBottom: '10px',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#DBF7EB',
                    border: '1px solid #2CF9A1',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Upgrade Now
                </button>
                <Typography
                  id="upgradeOptionOneLearnMore"
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.5px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={handleClickContinue}
                >
                  Learn More
                </Typography>
              </Box>
            </>
          )}
          {membership === 'current' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <button
                  id="bookCallButton"
                  style={{
                    marginBottom: '20px',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#FFEED6 ',
                    border: '1px solid #FFC068',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleBookACall}
                >
                  Book a Call
                </button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 33.31,
  height: 18.04,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 13,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(15px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(15px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 13,
    height: 13,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    opacity: 1,
    backgroundColor: '#97A3B7',
    boxSizing: 'border-box',
  },
}));

function Step1({
  onContinue,
  onOptionChange,
  isUserSegment,
  setOpenModal,
  month1,
  month2,
  annual1,
  annual2,
  annualChecked,
  onAnnualChange,
  userTier,
  tierMonth1,
  tierMonth2,
  tierAnnual1,
  tierAnnual2,
  handleContinueStep3,
  monthlyUpgrade1Calendar,
  monthlyUpgrade2Calendar,
  annualUpgrade1Calendar,
  annualUpgrade2Calendar,
  closeModal,
}) {
  const [selectedPlan, setSelectedPlan] = useState('month');
  const [selectedOption, setSelectedOption] = useState(1);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleAnnualChecked = (event) => {
    const checked = event.target.checked;
    onAnnualChange(checked);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    let selectedData;
    let counterpartData;

    onOptionChange(selectedPlan, option, selectedData, counterpartData);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: { xxs: '20px', sm: '30px' },
      }}
    >
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: {
            xxs: '32px',
            sm: '24px',
          },
          letterSpacing: '-0.43px',
          marginTop: '40px',
          marginBottom: '10px',
        }}
      >
        Upgrade today to launch your advisory board
      </Typography>

      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '23px',
          letterSpacing: '-.5px',
          marginBottom: '20px',
          maxWidth: '733px',
        }}
      >
        Choose your preferred type of advisory board to get started
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
        <Typography
          sx={{
            color: '#3171F6',
            textAlign: 'center',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '23px',
            letterSpacing: '-.43px',
            maxWidth: '733px',
            marginRight: '10px',
          }}
        >
          Go annual and save
        </Typography>
        <CustomSwitch
          id="goAnnualToggle"
          checked={annualChecked}
          onChange={handleAnnualChecked}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', xs: 'row' },
          justifyContent: { xxs: 'center', sm: 'space-between' },
          flexWrap: 'wrap',
          marginBottom: '30px',
          gap: 5,
        }}
      >
        {annualChecked ? (
          <>
            <CardPlanCode
              selected={selectedOption === 1}
              membership="crowdsourced"
              onClick={handleSelectOption}
              onContinue={onContinue}
              userSegment={isUserSegment}
              setOpenModal={setOpenModal}
              month1={month1}
              month2={month2}
              annual1={annual1}
              annual2={annual2}
              price={annual1.recurlyPlanCodePrice}
              annualChecked={annualChecked}
              userTier={userTier}
              tierMonth1={tierMonth1}
              tierMonth2={tierMonth2}
              tierAnnual1={tierAnnual1}
              tierAnnual2={tierAnnual2}
              handleContinueStep3={handleContinueStep3}
              annualUpgrade1Calendar={annualUpgrade1Calendar}
              closeModal={closeModal}
            />
            <CardPlanCode
              selected={selectedOption === 2}
              membership="traditional"
              onClick={handleSelectOption}
              onContinue={onContinue}
              userSegment={isUserSegment}
              setOpenModal={setOpenModal}
              month1={month1}
              month2={month2}
              annual1={annual1}
              annual2={annual2}
              price={annual2.recurlyPlanCodePrice}
              annualChecked={annualChecked}
              userTier={userTier}
              tierMonth1={tierMonth1}
              tierMonth2={tierMonth2}
              tierAnnual1={tierAnnual1}
              tierAnnual2={tierAnnual2}
              handleContinueStep3={handleContinueStep3}
              annualUpgrade2Calendar={annualUpgrade2Calendar}
              closeModal={closeModal}
            />
          </>
        ) : (
          <>
            <CardPlanCode
              selected={selectedOption === 1}
              membership="crowdsourced"
              onClick={handleSelectOption}
              onContinue={onContinue}
              userSegment={isUserSegment}
              setOpenModal={setOpenModal}
              price={month1.recurlyPlanCodePrice}
              annualChecked={annualChecked}
              month1={month1}
              month2={month2}
              annual1={annual1}
              annual2={annual2}
              userTier={userTier}
              tierMonth1={tierMonth1}
              tierMonth2={tierMonth2}
              tierAnnual1={tierAnnual1}
              tierAnnual2={tierAnnual2}
              handleContinueStep3={handleContinueStep3}
              monthlyUpgrade1Calendar={monthlyUpgrade1Calendar}
              closeModal={closeModal}
            />
            <CardPlanCode
              selected={selectedOption === 2}
              membership="traditional"
              onClick={handleSelectOption}
              onContinue={onContinue}
              userSegment={isUserSegment}
              setOpenModal={setOpenModal}
              price={month2.recurlyPlanCodePrice}
              annualChecked={annualChecked}
              month1={month1}
              month2={month2}
              annual1={annual1}
              annual2={annual2}
              userTier={userTier}
              tierMonth1={tierMonth1}
              tierMonth2={tierMonth2}
              tierAnnual1={tierAnnual1}
              tierAnnual2={tierAnnual2}
              handleContinueStep3={handleContinueStep3}
              monthlyUpgrade2Calendar={monthlyUpgrade2Calendar}
              closeModal={closeModal}
            />
          </>
        )}

        <CardPlanCode
          membership="current"
          handleContinueStep3={handleContinueStep3}
        />
      </Box>
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '23px',
          letterSpacing: '-.5px',
          marginTop: '5px',
          marginBottom: '40px',
          maxWidth: '733px',
        }}
      >
        By clicking Upgrade Now you agree to charge your card on file.
      </Typography>
    </Box>
  );
}

export default Step1;
