import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LockOutlined, AddCircleOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ACButton from './../../ACButton';
import ACSnackbar from './../../ACSnackbar';
import { v4 as uuidv4 } from 'uuid';
import { useAccount } from './../../../contexts/Account';
import AdvisorExperience from './../Experience';
import ChipList from './components/chipList';
import EditExperience from './components/editExperience';
import AdvisorImageUploader from './components/advisorImageUploader';
import { updateAdvisorAccountData } from './../../../services/advisor_services';
import { uploadToS3 } from './../../../services/Amplify/amplify-files-upload';
import { Enviroments } from './../../../services/utils/types';
import eventBus, { eventBusValues } from './../../../eventBus';

function EditAdvisorProfile(props) {
  const { t } = useTranslation();
  const { advisorId, values, isSaving, closeEditing } = props;
  const fontFamily = 'Poppins';
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { tags, accountDetails, editAccountInformation, isFreemium } =
    useAccount();

  const [biography, setBiography] = useState(values.biography);
  const [givenName, setGivenName] = useState(values.givenName);
  const [surName, setSurName] = useState(values.surName);
  const [headline, setHeadline] = useState(values.headline);
  const [experiences, setExperience] = useState(values.experiences || []);
  const [advisorSkills, setAdvisorSkills] = useState(() => {
    if (values.skillTagIds) {
      const filteredArray = tags.skills.filter((element) =>
        values.skillTagIds.includes(String(element.id))
      );
      const mapSkillTag = filteredArray.map((element) => {
        return {
          id: element.id,
          label: element.label,
        };
      });
      return mapSkillTag;
    }
    return [];
  });
  const [advisorIndustires, setAdvisorIndustires] = useState(() => {
    if (values.industryTagIds) {
      const filteredArray = tags.industries.filter((element) =>
        values.industryTagIds.includes(String(element.id))
      );
      const mapIndustryTag = filteredArray.map((element) => {
        return {
          id: element.id,
          label: element.label,
        };
      });
      return mapIndustryTag;
    }
    return [];
  });
  const [favoriteSkills, setFavoriteSkills] = useState(
    values.favoriteSkillsIds || []
  );
  const [favoriteIndustries, setFavoriteIndustries] = useState(
    values.favoriteIndustriesIds || []
  );
  const [interests, setInterests] = useState(values.interests || []);
  const [enableRate] = useState(values.meetingsEnabled);
  const [meetingRate, setMeetingRate] = useState(values.meetingRate || 500.0);
  const [avatarImage, setAvatarImage] = useState();
  const [originalImage, setOriginalImage] = useState();

  const [selectedExp, setSelectedExp] = useState(-1);
  const [openEditExp, setOpenEditExp] = useState(false);
  const [tempExperience, setTempExperience] = useState({});
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const interestsOptions = [
    'Board Seats',
    'Consulting',
    'Mentoring',
    'Networking',
    'New Ventures',
    'Full-Time Roles',
  ];
  const inputLabelProps = {
    style: {
      fontFamily: fontFamily,
    },
  };

  function removeFavoriteSkill(skillId) {
    const newArr = favoriteSkills.filter((id) => id !== skillId);
    setFavoriteSkills(newArr);
  }

  function addFavoriteSkill(skillId) {
    setFavoriteSkills(
      favoriteSkills.filter((id) => advisorSkills.map((a) => a.id).includes(id))
    );
    if (favoriteSkills.length >= 8) {
      setErrorMessage(t('ADVISOR-EDIT-FAVORITE-SKILLS-WARNING'));
      setErrorSnackbar(true);
    } else {
      setFavoriteSkills([...favoriteSkills, skillId]);
    }
  }

  function removeFavoriteIndustry(induId) {
    const newArr = favoriteIndustries.filter((id) => id !== induId);
    setFavoriteIndustries(newArr);
  }

  function addFavoriteIndustry(induId) {
    setFavoriteIndustries(
      favoriteIndustries.filter((id) =>
        advisorIndustires.map((a) => a.id).includes(id)
      )
    );
    if (favoriteIndustries.length >= 3) {
      setErrorMessage(t('ADVISOR-EDIT-FAVORITE-INDUSTRIES-WARNING'));
      setErrorSnackbar(true);
    } else {
      setFavoriteIndustries([...favoriteIndustries, induId]);
    }
  }

  function discardExperience() {
    setOpenEditExp(false);
    setSelectedExp(-1);
  }

  function saveExperience(exp) {
    let tempExperiences = experiences;
    if (selectedExp === -1) {
      tempExperiences = [...experiences, exp];
    } else {
      tempExperiences = [
        ...experiences.slice(0, selectedExp),
        { ...exp },
        ...experiences.slice(selectedExp + 1),
      ];
    }
    if (exp.displayTitle) {
      tempExperiences = tempExperiences.map((experience) => {
        if (JSON.stringify(experience) !== JSON.stringify(exp)) {
          return { ...experience, displayTitle: false };
        }
        return experience;
      });
    }

    setExperience(tempExperiences);
    discardExperience();

    return tempExperience;
  }

  function removeExperience(index) {
    setExperience(experiences.filter((_, i) => i !== index));
    if (index === selectedExp) {
      discardExperience();
    }
  }

  function selectExperience(index) {
    setSelectedExp(index);
    setOpenEditExp(true);
  }

  function newExperience() {
    setSelectedExp(-1);
    setOpenEditExp(true);
  }

  function setTempExpChanges(exp) {
    setTempExperience(exp);
  }

  async function saveChanges() {
    if (!givenName || !surName) {
      setErrorMessage(t('ADVISOR-EDIT-NAMES-REQUIRED-WARNING'));
      setErrorSnackbar(true);
      closeEditing(false);
      return;
    }

    try {
      let uploadedAvatarId = null;
      let uploadedOriginalId = null;
      let bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES;
      if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
        bucketImages = process.env.REACT_APP_S3_BUCKET_IMAGES.replace(
          'us-west-2',
          'us-east-1'
        );
      }
      if (avatarImage) {
        const imageId = `${uuidv4()}.${avatarImage.type.split('/')[1]}`;
        await uploadToS3({
          BLOB_LOAD: avatarImage,
          CONTENT_TYPE: avatarImage.type,
          FILE_ID: imageId,
          S3_BUCKET: bucketImages,
        });
        uploadedAvatarId = `public/${imageId}`;
      }
      if (originalImage) {
        const imageId = `${uuidv4()}.${originalImage.type.split('/')[1]}`;
        await uploadToS3({
          BLOB_LOAD: originalImage,
          CONTENT_TYPE: originalImage.type,
          FILE_ID: imageId,
          S3_BUCKET: process.env.REACT_APP_S3_BUCKET_ORIGINAL_IMAGES,
        });
        uploadedOriginalId = `public/${imageId}`;
      }
      const DATA = {
        ADVISOR_ID: advisorId,
        BIOGRAPHY: biography || '',
        GIVENNAME: givenName,
        SURNAME: surName,
        HEADLINE: headline || '',
        EXPERIENCES: experiences.map((e) => JSON.stringify(e)),
        ADVISORSKILLS: advisorSkills.map((a) => a.id),
        ADVISORINDUSTIRES: advisorIndustires.map((a) => a.id),
        FAVORITESKILLS: favoriteSkills.filter((skillId) =>
          advisorSkills.map((a) => a.id).includes(skillId)
        ),
        FAVORITEINDUSTRIES: favoriteIndustries.filter((induId) =>
          advisorIndustires.map((a) => a.id).includes(induId)
        ),
        AVATARIMAGEID: uploadedAvatarId || '',
        ORIGINALIMAGEID: uploadedOriginalId || '',
        MEETINGRATE: meetingRate
          ? Math.trunc(Number(meetingRate) * 100) / 100
          : 500.0,
        INTERESTS: interests,
      };
      if (openEditExp) {
        let newExp = saveExperience(tempExperience);
        DATA.EXPERIENCES = newExp.map((e) => JSON.stringify(e));
      }
      const res = await updateAdvisorAccountData(DATA);
      if (res.data && res.data.updateAdvisor) {
        const newAdvisor = res.data.updateAdvisor;
        editAccountInformation(newAdvisor);
        closeEditing(true);
      } else {
        throw res;
      }
    } catch (error) {
      if (error.message && error.message.includes('Network Error')) {
        setErrorMessage('Image size must be 5mb or less');
      } else {
        setErrorMessage(t('ADVISOR-EDIT-UNHANDLED-ERROR'));
      }
      setErrorSnackbar(true);
      closeEditing(false);
    }
  }

  useEffect(() => {
    if (isSaving) {
      saveChanges();
    }
  }, [isSaving]);

  function setImageFiles(images) {
    setAvatarImage(images.image);
    setOriginalImage(images.originalImage);
  }

  function upgradeAdvisor() {
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
    } else {
      window.location.replace(
        `https://register.advisorycloud.com/upgrade-plan-hourly-rate/?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}`
      );
    }
  }

  return (
    <div>
      <ACSnackbar
        open={errorSnackbar}
        text={errorMessage}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <Box
        sx={{
          display: 'flex',
          marginTop: '10px',
          justifyContent: { xxs: 'center', xs: 'flex-start' },
          alignItems: 'center',
        }}
      >
        <Box
          id="editProfileHeader"
          sx={{
            display: 'flex',
            flexDirection: { xxs: 'column', xs: 'row' },
            alignContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xxs: 'center', xs: 'flex-start' },
              marginLeft: { xxs: '0px', xs: '25px' },
              marginTop: { xxs: '20px', xs: '0px' },
              marginRight: { xxs: '0px', xs: '20px' },
            }}
          >
            <AdvisorImageUploader resultFiles={setImageFiles} />
          </Box>
          <div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xxs: 'column', xs: 'row' },
                marginTop: { xxs: '0px', xs: '20px' },
              }}
            >
              <TextField
                placeholder="First Name"
                label="First Name"
                variant="outlined"
                size="small"
                required
                InputLabelProps={inputLabelProps}
                inputProps={{
                  style: {
                    fontSize: '120%',
                    fontWeight: 300,
                    fontFamily: 'Poppins',
                  },
                }}
                sx={{
                  marginRight: { xxs: 0, xs: '10px' },
                  marginTop: { xxs: '15px', xs: '0px' },
                }}
                value={givenName || ''}
                onChange={(evt) => setGivenName(evt.target.value)}
              />
              <TextField
                placeholder="Last Name"
                label="Last Name"
                variant="outlined"
                size="small"
                required
                InputLabelProps={inputLabelProps}
                inputProps={{
                  style: {
                    fontSize: '120%',
                    fontWeight: 300,
                    fontFamily: 'Poppins',
                  },
                }}
                sx={{
                  marginTop: { xxs: '15px', xs: '0px' },
                  width: { xxs: '90vw', xs: 'auto' },
                }}
                value={surName || ''}
                onChange={(evt) => setSurName(evt.target.value)}
              />
            </Box>
            <div style={{ marginTop: isSM ? '15px' : '10px' }}>
              <TextField
                id="advisorHeadline"
                placeholder="Short description of your professional expertise"
                label="Headline"
                variant="outlined"
                fullWidth={!isSM}
                multiline
                minRows={2}
                value={headline || ''}
                inputProps={{
                  maxLength: 200,
                  style: {
                    fontFamily: fontFamily,
                  },
                }}
                sx={{ width: '100%' }}
                InputLabelProps={inputLabelProps}
                onChange={(evt) => setHeadline(evt.target.value)}
              />
              <InputLabel htmlFor="advisorHeadline">
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: { xxs: '5px', xs: '10px' },
                      marginBottom: { xxs: '15px', xs: '0px' },
                    }}
                  >
                    {t('ADVISOR-EDIT-MAX-HEADLINE')}
                  </Typography>
                </div>
              </InputLabel>
            </div>
          </div>
        </Box>
      </Box>
      <div style={{ margin: '30px' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '120%',
            marginBottom: 0,
          }}
        >
          {t('ADVISOR-ACCOUNT-SKILLS-LABEL')}
        </Typography>

        <div id="editProfileSkillsBox" style={{ marginTop: '-5px' }}>
          <InputLabel htmlFor="advisorSkills">
            <div style={{ display: 'flex', display: 'flex' }}>
              <Typography variant="caption">
                {t('ADVISOR-EDIT-REQUIRED-SKILLS')}
              </Typography>
            </div>
          </InputLabel>
          <Autocomplete
            defaultValue={advisorSkills}
            value={advisorSkills}
            onChange={(event, vals) => {
              if (vals.length > 30) {
                setErrorMessage(t('ADVISOR-EDIT-MAX-SKILLS-WARNING'));
                setErrorSnackbar(true);
              } else {
                setAdvisorSkills(vals);
              }
            }}
            renderTags={(options) => (
              <ChipList
                staticClass="tag"
                edit
                canBeStar
                handleStar={(id, del) => {
                  if (del) removeFavoriteSkill(id);
                  else addFavoriteSkill(id);
                }}
                handleDelete={(option) => {
                  setAdvisorSkills(
                    advisorSkills.filter((entry) => entry.id !== option.id)
                  );
                }}
                starList={favoriteSkills}
                items={options}
                fontFamily={fontFamily}
              />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableCloseOnSelect
            id="advisorSkills"
            input-data-cy="txtAdvisorSkills"
            label={t('STEP1-ADVISOR-SKILLS-SELECT-LABEL')}
            multiple
            options={tags.skills}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-cy': 'txtAdvisorSkills',
                  style: {
                    fontFamily: fontFamily,
                  },
                }}
                placeholder={t('STEP1-ADVISOR-SKILLS-SELECT-PLACEHOLDER')}
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'row',
          margin: '30px',
        }}
      >
        <div id="editProfileRate">
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '120%',
            }}
          >
            {t('ADVISOR-ACCOUNT-RATE-LABEL')}
          </Typography>
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            variant="outlined"
            type="number"
            size="small"
            style={{ width: isSM ? '90%' : '50%', fontFamily: fontFamily }}
            disabled={!enableRate}
            value={meetingRate}
            onChange={(evt) => setMeetingRate(evt.target.value)}
          />
        </div>
        {enableRate ? null : (
          <div
            id="editProfileRateLock"
            style={{
              marginTop: isSM ? '5px' : '24px',
              marginLeft: isSM ? '0px' : '-50px',
            }}
          >
            <Typography sx={{ fontSize: '90%' }}>
              <LockOutlined
                color="primary"
                sx={{ fontSize: '15px', marginRight: '2px' }}
              />
              {t('ADVISOR-UNLOCK-RATE')}
            </Typography>
            <Typography
              color="primary"
              onClick={upgradeAdvisor}
              sx={{
                fontSize: '90%',
                cursor: 'pointer',
              }}
            >
              {t('ADVISOR-UPGRADE-RATE')}
            </Typography>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'row',
          margin: '30px',
        }}
      >
        <div style={{ width: '100%' }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '120%',
            }}
          >
            {t('STEP2-YOUR-INTERESTS-INPUT-LABEL')}
          </Typography>
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <InputLabel
              sx={{ color: '#a9a9a9', fontFamily: fontFamily }}
              id="select-interests-label"
            >
              Select all interests that apply
            </InputLabel>
            <Select
              id="editProfileInterestsInput"
              labelId="select-interests-label"
              label="Select all interests that apply"
              variant="outlined"
              style={{ width: '100%', fontFamily: fontFamily }}
              multiple
              value={interests}
              onChange={(evt) => setInterests(evt.target.value)}
            >
              {interestsOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ margin: '30px' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '120%',
            marginBottom: '10px',
          }}
        >
          {t('ADVISOR-BIOGRAPHY-LABEL')}
        </Typography>
        <TextField
          placeholder={t('ADVISOR-BIOGRAPHY-LABEL')}
          variant="outlined"
          inputProps={{
            style: {
              fontFamily: fontFamily,
            },
          }}
          id="editProfileBioInput"
          fullWidth
          multiline
          minRows={10}
          value={biography || ''}
          onChange={(evt) => setBiography(evt.target.value)}
        />
      </div>
      <div style={{ margin: '30px' }} id="editProfileExperienceBox">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '120%',
              marginBottom: '10px',
            }}
          >
            {t('ADVISOR-ACCOUNT-EXPERIENCE-LABEL')}
          </Typography>
          {!openEditExp ? (
            <div
              style={{ width: isSM ? '45%' : '25%' }}
              id="editProfilePositionButton"
            >
              <ACButton
                color="primary"
                size="medium"
                variant="outlined"
                style={{
                  borderWidth: 2,
                  borderRadius: 5,
                  paddingTop: isSM ? 10 : 0,
                  paddingBottom: isSM ? 10 : 0,
                  paddingLeft: isSM ? 35 : 0,
                  paddingRight: isSM ? 35 : 0,
                  marginRight: 10,
                  width: '100%',
                }}
                onClick={newExperience}
              >
                <AddCircleOutlineOutlined
                  sx={{
                    fontSize: isSM ? '15px' : '20px',
                    marginRight: '15px',
                    marginLeft: '3px',
                  }}
                />

                <Typography
                  sx={{
                    fontSize: isSM ? '80%' : '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('ADVISOR-EMPTY-EXPERIENCE')}
                </Typography>
              </ACButton>
            </div>
          ) : null}
        </div>
        {openEditExp ? (
          <EditExperience
            key={selectedExp}
            experience={
              selectedExp === -1 ? {} : experiences[selectedExp] || {}
            }
            savePosition={saveExperience}
            discardChanges={discardExperience}
            tempChanges={setTempExpChanges}
          />
        ) : null}
        {experiences && experiences.length ? (
          <AdvisorExperience
            experienceArray={experiences || []}
            edit
            editAction={selectExperience}
            deleteAction={removeExperience}
          />
        ) : null}
      </div>
      <div style={{ margin: '30px' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '120%',
            marginBottom: '0px',
          }}
        >
          {t('ADVISOR-ACCOUNT-INDUSTRY-LABEL')}
        </Typography>

        <div style={{ marginTop: '-5px' }} id="editProfileIndustriesButton">
          <InputLabel htmlFor="advisorIndustries">
            <div style={{ justifyContent: 'end', justifyContent: 'end' }}>
              <Typography variant="caption">
                {t('ADVISOR-EDIT-REQUIRED-INDUSTRIES')}
              </Typography>
            </div>
          </InputLabel>
          <Autocomplete
            value={advisorIndustires}
            onChange={(event, vals) => {
              if (vals.length > 10) {
                setErrorMessage(t('ADVISOR-EDIT-MAX-INDUSTRIES-WARNING'));
                setErrorSnackbar(true);
              } else {
                setAdvisorIndustires(vals);
              }
            }}
            renderTags={(options) => (
              <ChipList
                staticClass="tag"
                canBeStar
                starList={favoriteIndustries}
                edit
                handleStar={(id, del) => {
                  if (del) removeFavoriteIndustry(id);
                  else addFavoriteIndustry(id);
                }}
                handleDelete={(option) => {
                  setAdvisorIndustires(
                    advisorIndustires.filter((entry) => entry !== option)
                  );
                }}
                items={options}
                fontFamily={fontFamily}
              />
            )}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableCloseOnSelect
            id="advisorIndustries"
            input-data-cy="txtAdvisorIndustries"
            label={t('STEP1-ADVISOR-SKILLS-SELECT-LABEL')}
            multiple
            options={tags.industries}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-cy': 'txtAdvisorIndustries',
                  style: {
                    fontFamily: fontFamily,
                  },
                }}
                placeholder={t('STEP1-ADVISOR-SKILLS-SELECT-PLACEHOLDER')}
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default EditAdvisorProfile;
