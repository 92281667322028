import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useMediaQuery,
  Dialog,
  Typography,
  Box,
  DialogContent,
  IconButton,
} from '@mui/material';
import ACSnackbar from './../../ACSnackbar';
import { useAccount } from './../../../contexts/Account';
import { SnackbarType } from './../../../services/utils/types';
import Shield from './shield.png';
import Team from './team-people.png';
import Conversation from './conversation.png';
import Arrow from './arrow.png';
import { ReactComponent as Recomendation } from './recomendation.svg';
import {
  mutationConvertCompanyPlanCodeEarly,
  mutationFreeTrialUpgradeCompany,
} from './../../../services/company_services';
import './styles.css';
import CompanyIcon from './company.png';
import { ReactComponent as TeamGreen } from './team-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import LoadingLogoSkeleton from '../../SkeletonLoading/loadingLogoSkeleton';
import { sendFSEventOnUpgrade } from '../../../services/FullStory';

const errorCodes = {
  validationError: 'VALIDATION_ERROR',
  recurlyError: 'RECURLY_ERROR',
  inAppError: 'IN_APP_ERROR',
  salesforceError: 'SALESFORCE_ERROR',
};

const Step2 = (props) => {
  const {
    planData,
    onPlanChange,
    goBack,
    closeModal,
    month1,
    month2,
    annual1,
    annual2,
    hideBack,
    setSelectedPlanFinal,
    handleContinueStep3,
    annualChecked,
    tierMonth1,
    tierMonth2,
    tierAnnual1,
    tierAnnual2,
    monthlyUpgrade1Calendar,
    monthlyUpgrade2Calendar,
    annualUpgrade1Calendar,
    annualUpgrade2Calendar,
    currentPlanUpgrade,
    currentPlanCalendar,
    currentPlanTier,
  } = props;

  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);
  const {
    accountDetails,
    isFreemium,
    editAccountInformation,
    currentUserInfo,
    type,
    currentPlan,
  } = useAccount();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data, option, plan } = planData;
  const [selectedPlan, setSelectedPlan] = useState(option);
  const location = useLocation();

  function handleClose() {
    closeModal();
    setSelectedPlan(1);
  }

  const SelectedPlan = () => {
    if (!currentPlanUpgrade) {
      if (annualChecked === true) {
        if (selectedPlan === 1) {
          return annual1.recurlyPlanCode;
        } else {
          return annual2.recurlyPlanCode;
        }
      } else {
        if (selectedPlan === 1) {
          return month1.recurlyPlanCode;
        } else {
          return month2.recurlyPlanCode;
        }
      }
    }
  };

  const planCode = SelectedPlan();

  const handleClickOpeDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const sendFSEvent = (success, errorMessage = '', error) => {
    sendFSEventOnUpgrade({
      accountDetails,
      currentPlan: currentPlan,
      newPlan: currentPlan,
      source: location?.state?.fromBanner ? 'banner' : 'gate',
      type,
      success,
      errorMessage: success ? null : errorMessage,
      error: success ? null : error,
    });
  };

  async function upgradeCall() {
    if (currentPlanUpgrade) {
      if (!loading) {
        setLoading(true);
        mutationConvertCompanyPlanCodeEarly({
          COMPANY_ID: accountDetails.id,
          COMPANY_CONTACT_ID: currentUserInfo.username,
          IS_UPGRADE: false,
        })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.convertCompanyPlanCodeEarly
            ) {
              const newCompany = response.data.convertCompanyPlanCodeEarly;
              setSnackMessage(
                'Your account has been upgraded, you now have full access to AdvisoryCloud'
              );
              setSnackbarSuccess(true);
              setLoading(false);
              editAccountInformation(
                {
                  freeTrialEndsOn: newCompany.freeTrialEndsOn,
                  recurlyPlanCode: newCompany.recurlyPlanCode,
                },
                true
              );
              handleCloseDialog();
              sendFSEvent(true);
              if (currentPlanCalendar) {
                handleContinueStep3(true, currentPlanUpgrade.recurlyPlanCode);
              } else {
                closeModal();
              }
            }
          })
          .catch((e) => {
            let errorMessage = '';
            try {
              const err = JSON.parse(e.errors[0].message);
              if (err.code === errorCodes.recurlyError) {
                errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_RECURLY');
              } else if (err.code === errorCodes.dynamoError) {
                errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_DYNAMO');
              } else if (err.code === errorCodes.salesforceError) {
                setSnackMessage(
                  'Your account has been upgraded, you now have full access to AdvisoryCloud'
                );
                setSnackbarSuccess(true);
                return;
              } else {
                errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
              }
              setLoading(false);
              handleCloseDialog();
            } catch (err) {
              errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
              setLoading(false);
              handleCloseDialog();
            }
            sendFSEvent(false, errorMessage, e);
            setSnackMessage(errorMessage);
            setSnackbarError(true);
          });
      } else {
        setSnackMessage('Select an upgrade option to continue');
        setSnackbarSuccess(true);
        handleCloseDialog();
      }
    } else {
      if (selectedPlan && !loading) {
        setLoading(true);
        mutationFreeTrialUpgradeCompany({
          COMPANY_ID: accountDetails.id,
          COMPANY_CONTACT_ID: currentUserInfo.username,
          IS_UPGRADE: planCode,
        })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.freeTrialUpgradeCompany
            ) {
              const newCompany = response.data.freeTrialUpgradeCompany;
              setSnackMessage(
                'Your account has been upgraded, you now have full access to AdvisoryCloud'
              );
              setSnackbarSuccess(true);
              setLoading(false);
              editAccountInformation(
                {
                  freeTrialEndsOn: newCompany.freeTrialEndsOn,
                  recurlyPlanCode: newCompany.recurlyPlanCode,
                },
                true
              );
              handleCloseDialog();
              sendFSEvent(true);
              if (annualChecked) {
                if (selectedPlan === 1) {
                  if (annualUpgrade1Calendar) {
                    handleContinueStep3(true, planCode);
                  } else {
                    closeModal();
                  }
                } else {
                  if (annualUpgrade2Calendar) {
                    handleContinueStep3(true, planCode);
                  } else {
                    closeModal();
                  }
                }
              } else {
                if (selectedPlan === 1) {
                  if (monthlyUpgrade1Calendar) {
                    handleContinueStep3(true, planCode);
                  } else {
                    closeModal();
                  }
                } else {
                  if (monthlyUpgrade2Calendar) {
                    handleContinueStep3(true, planCode);
                  }
                }
              }
            }
          })
          .catch((e) => {
            let errorMessage = '';
            try {
              const err = JSON.parse(e.errors[0].message);
              if (err.code === errorCodes.recurlyError) {
                errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_RECURLY');
              } else if (err.code === errorCodes.dynamoError) {
                errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_DYNAMO');
              } else if (err.code === errorCodes.salesforceError) {
                setSnackMessage(
                  'Your account has been upgraded, you now have full access to AdvisoryCloud'
                );
                setSnackbarSuccess(true);
              } else {
                errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
                return;
              }
              setLoading(false);
              handleCloseDialog();
            } catch (err) {
              errorMessage = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
              setLoading(false);
              handleCloseDialog();
            }
            sendFSEvent(false, errorMessage, e);
            setSnackMessage(errorMessage);
            setSnackbarError(true);
          });
      } else {
        setSnackMessage('Select an upgrade option to continue');
        setSnackbarSuccess(true);
        handleCloseDialog();
      }
    }
  }

  useEffect(() => {
    if (isSM) {
      const modalElement = document.getElementById(
        'companyFreemiumUpgradeModal'
      );
      if (modalElement) {
        modalElement.scrollTop = 0;
      }
    }
  }, [isSM]);

  return (
    <div>
      <Box
        id="freemiumUpgradeAdvisorModal"
        sx={{
          borderRadius: isSM ? '0px' : '15px',
          marginTop: '50px',
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              borderRadius: 10,
              width: 300,
              height: 206,
              border: '1px solid #E0E0E0',
              alignItems: 'center',
            },
          }}
        >
          <DialogContent style={{ textAlign: 'center', position: 'relative' }}>
            <IconButton
              disableRipple
              size="small"
              onClick={handleCloseDialog}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '24px',
                letterSpacing: '-0.43px',
                marginTop: '15px',
                textAlign: 'center',
              }}
            >
              Confirm your upgrade!
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '20px',
                letterSpacing: '-0.5px',
                width: '235px',
                marginTop: '5px',
                textAlign: 'center',
              }}
            >
              Clicking “confirm” will charge your card on file, and upgrade your
              account
            </Typography>

            <button
              disabled={loading}
              variant="contained"
              color="primary"
              style={{
                marginBottom: '10px',
                position: 'relative',
                fontSize: '15px',
                borderRadius: '999px',
                fontWeight: 700,
                width: '168px',
                height: '45px',
                backgroundColor: loading ? '#e2e2e2' : '#3171F6',
                border: 'none',
                color: '#FFFFFF',
                borderColor: 'none',
                fontFamily: 'Poppins',
                outline: 'none',
                cursor: loading ? 'default' : 'pointer',
                marginTop: '20px',
              }}
              onClick={upgradeCall}
            >
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LoadingLogoSkeleton />
                </Box>
              ) : (
                ' Confirm'
              )}
            </button>
          </DialogContent>
        </Dialog>
        {!hideBack && (
          <Box
            sx={{
              position: 'absolute',
              width: '15px',
              left: { xxs: '20px', sm: '70px' },
              top: { xxs: '20px', sm: '60px' },
              cursor: 'pointer',
            }}
          >
            <img
              onClick={goBack}
              src={Arrow}
              alt="arrow"
              style={{
                width: '16px',
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: { xxs: '70px', sm: '40px' },
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              width: { xxs: '90%', sm: '100%' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                textAlign: 'center',
                fontWeight: 800,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#232B35',
                marginBottom: '5px',
              }}
            >
              Upgrade today to launch your advisory board
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: '14px',
                lineHeight: '24px',
                textAlign: 'center',
                fontWeight: 500,
                color: '#232B35',
              }}
            >
              {planData.option === 1
                ? 'Get regular feedback from a wide range of advisors in our network'
                : ' Exclusive advisory board of up to 12 hand-picked advisors'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xxs: 'column-reverse', sm: 'row' },
            padding: { xxs: '0px', sm: '0px 45px 0px 0px' },
            marginTop: '50px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              padding: { xxs: '0px 30px', sm: '0px 60px' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#232B35',
                marginBottom: '20px',
              }}
            >
              Here’s what you get
            </Typography>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: isSM ? '10px' : '20px',
              }}
            >
              <img
                src={Shield}
                alt="Conversation"
                style={{ marginRight: '20px' }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#232B35',
                }}
              >
                {planData.option === 1
                  ? 'Work with a dedicated account manager to launch and manage your advisory board'
                  : 'Work with a dedicated account manager to launch and manage your hand-picked advisory board'}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: isSM ? '20px' : '40px',
              }}
            >
              <img src={Team} alt="stonks" style={{ marginRight: '20px' }} />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#232B35',
                }}
              >
                {planData.option === 1
                  ? 'Crowdsource feedback and advice from a wide range of advisors in our network'
                  : 'Get feedback and advice from a vetted group of advisors hand-picked from our network'}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: isSM ? '20px' : '40px',
                marginBottom: '30px',
              }}
            >
              <img
                src={Conversation}
                alt="shield"
                style={{ marginRight: '20px' }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#232B35',
                }}
              >
                {planData.option === 1
                  ? 'Hold quarterly advisory board meetings moderated by AdvisoryCloud'
                  : 'Hold quarterly advisory board meetings moderated by AdvisoryCloud'}
              </Typography>
            </div>
            <Box
              sx={{
                display: 'flex',
                alignSelf: { xxs: 'center', xs: 'flex-start' },
              }}
            >
              <Recomendation style={{ marginTop: '30px' }} />
            </Box>
          </Box>

          {/* Card */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: { xxs: '40px', sm: '0px' },
            }}
          >
            <Box
              //onClick={handleClick}
              sx={{
                width: '330px',
                height: '476px',
                borderColor: '#3171F6',
                borderRadius: '15px',
                position: 'relative',
                zIndex: 1,
                boxShadow: '0 0 5px 0px #3171F6',
                padding: '20px 30px',
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                  }}
                >
                  {planData.option === 1 ? (
                    <TeamGreen alt="company" style={{}} />
                  ) : (
                    <img
                      src={CompanyIcon}
                      alt="company"
                      style={{ width: '52px', height: '52px' }}
                    />
                  )}
                </Box>

                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    marginTop: '15px',
                    marginBottom: '10px',
                    textAlign: 'center',
                  }}
                >
                  {currentPlanTier && currentPlanTier}
                  {annualChecked ? (
                    <>{planData.option === 1 ? tierAnnual1 : tierAnnual2}</>
                  ) : (
                    <>{planData.option === 1 ? tierMonth1 : tierMonth2}</>
                  )}
                </Typography>

                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '-0.5px',
                    marginBottom: '10px',
                    textAlign: 'center',
                  }}
                >
                  {planData.option === 1
                    ? 'Assemble an advisory board where any advisor in our private network can join and give you their input'
                    : 'Assemble a hand-selected advisory board of up to 12 advisors sourced and vetted by our team'}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      color: '#232B35',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      textAlign: 'center',
                    }}
                  >
                    $
                    {currentPlanUpgrade ? (
                      <>
                        {currentPlanUpgrade.recurlyPlanCodeTerm === 'yr' ? (
                          <>
                            {Math.round(
                              currentPlanUpgrade.recurlyPlanCodePrice / 12
                            )}
                          </>
                        ) : (
                          <>{currentPlanUpgrade.recurlyPlanCodePrice}</>
                        )}
                      </>
                    ) : (
                      <>
                        {annualChecked ? (
                          <>
                            {planData.option === 1
                              ? Math.round(annual1.recurlyPlanCodePrice / 12)
                              : Math.round(annual2.recurlyPlanCodePrice / 12)}
                          </>
                        ) : (
                          <>
                            {planData.option === 1
                              ? month1.recurlyPlanCodePrice
                              : month2.recurlyPlanCodePrice}
                          </>
                        )}
                      </>
                    )}
                    <Typography
                      sx={{
                        fontSize: '10px',
                        marginLeft: '5px',
                      }}
                    >
                      /mo
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {!currentPlanUpgrade && annualChecked && (
                      <>
                        {planData.option === 1
                          ? `billed annually $${annual1.recurlyPlanCodePrice}`
                          : `billed annually $${annual2.recurlyPlanCodePrice}`}
                      </>
                    )}
                    {currentPlanUpgrade &&
                      currentPlanUpgrade.recurlyPlanCodeTerm === 'yr' && (
                        <>
                          {`billed annually $${currentPlanUpgrade.recurlyPlanCodePrice}`}
                        </>
                      )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '0px',
                  }}
                >
                  <button
                    disabled={loading}
                    type="button"
                    style={{
                      marginBottom: '20px',
                      position: 'relative',
                      fontSize: '15px',
                      borderRadius: '999px',
                      fontWeight: 700,
                      width: '100%',
                      height: '44px',
                      backgroundColor: loading
                        ? 'grey'
                        : planData.option === 1
                        ? '#DBF7EB'
                        : '#D7F1FD',
                      border: loading
                        ? '1px solid grey'
                        : planData.option === 1
                        ? '1px solid #2CF9A1'
                        : '1px solid #4BC6FF',
                      color: loading ? '#ffffff' : '#232B35',
                      borderColor: 'none',
                      fontFamily: 'Poppins',
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                    variant="contained"
                    onClick={handleClickOpeDialog}
                  >
                    Upgrade Now
                  </button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '5.87px',
                      height: '5.87px',
                      backgroundColor:
                        planData.option === 1 ? '#DBF7EB' : '#D7F1FD',
                      borderRadius: 99,
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#232B35',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '23px',
                      letterSpacing: '-0.5px',
                    }}
                  >
                    Access to 10,000+ advisors
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '5.87px',
                      height: '5.87px',
                      backgroundColor:
                        planData.option === 1 ? '#DBF7EB' : '#D7F1FD',
                      borderRadius: 99,
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#232B35',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '23px',
                      letterSpacing: '-0.5px',
                    }}
                  >
                    {planData.option === 1
                      ? 'Digital boardroom and forum'
                      : 'Digital boardroom and forum'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '5.87px',
                      height: '5.87px',
                      backgroundColor:
                        planData.option === 1 ? '#DBF7EB' : '#D7F1FD',
                      borderRadius: 99,
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#232B35',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '23px',
                      letterSpacing: '-0.5px',
                    }}
                  >
                    {planData.option === 1
                      ? 'Let any advisor join your board'
                      : 'Vetted & hand-picked advisors'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '5.87px',
                      height: '5.87px',
                      backgroundColor:
                        planData.option === 1 ? '#DBF7EB' : '#D7F1FD',
                      borderRadius: 99,
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#232B35',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '23px',
                      letterSpacing: '-0.5px',
                    }}
                  >
                    {planData.option === 1
                      ? 'Dedicated account manager'
                      : 'Dedicated account manager'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    sx={{
                      width: '5.87px',
                      height: '5.87px',
                      backgroundColor:
                        planData.option === 1 ? '#DBF7EB' : '#D7F1FD',
                      borderRadius: 99,
                      marginRight: '10px',
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#232B35',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '23px',
                      letterSpacing: '-0.5px',
                    }}
                  >
                    {planData.option === 1
                      ? 'Moderated board meetings'
                      : 'Moderated board meetings'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '23px',
          letterSpacing: '-.5px',
          marginTop: '40px',
          marginBottom: { xxs: '40px', sm: '0px' },
        }}
      >
        By clicking Upgrade Now you agree to charge your card on file.
      </Typography>
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => {
          setSnackbarSuccess(false);
        }}
        autoHideDuration={3000}
      />
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarError}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.ERROR}
        onClose={() => {
          setSnackbarError(false);
        }}
        autoHideDuration={6000}
      />
    </div>
  );
};

export default Step2;
