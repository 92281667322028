import { AccountType } from './utils/types';

export async function sendFSEventOnUpgrade({
  accountDetails,
  currentPlan,
  newPlan,
  source,
  type,
  success,
  errorMessage,
  error,
}) {
  let errorInText = 'Not able to parse the error';
  if (!success) {
    try {
      errorInText = JSON.stringify(error);
    } catch (e) {
      console.error('Not able to stringify error', e);
      try {
        errorInText = error.toString();
      } catch (e) {
        console.error('Not able to convert error to string', e);
      }
    }
  }

  try {
    const properties = {
      accountType_str: type === AccountType.ADVISOR ? 'b2c' : 'b2b',
      userSegment_str: accountDetails?.userSegment || '',
      source_str: source,
      url_str: window.location.href,
      currentPlancode_str: currentPlan?.recurlyPlanCode || '',
      currentPlancodeTier_str: currentPlan?.salesforceValues.tier || '',
      upgradePlancode_str: newPlan?.recurlyPlanCode || '',
      upgradePlancodeTier_str: newPlan?.salesforceValues.tier || '',
      status_str: success ? 'success' : 'failure',
      errorMessage_str: success ? '' : errorMessage,
      technicalErrorMessage_str: success ? '' : errorInText,
    };
    console.log('Sending FullStory event "Upgrade Attempt"', properties);

    FS('trackEvent', {
      name: 'Upgrade Attempt',
      properties,
    });
  } catch (error) {
    console.error('Error sending FullStory event', error);
  }
}
