export default {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_KEY,
    key: 'web-users',
  },
};

export const featureFlags = {
  publicProfilesCustomMetadata: 'public_profiles_custom_metadata',
  B2B_freemium_AOBF: 'B2B_freemium_AOBF',
  b2b_profile_updates: 'b2b_profile_updates',
  top_matches: 'top_matches',
};
