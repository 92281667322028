import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from './../../../contexts/Account';
import AdvisorDetailCard from './../DetailCard';
import eventBus, { eventBusValues } from './../../../eventBus';
import { AccountType } from './../../../services/utils/types';
import useSavedAdvisor from '../../../hooks/useSavedAdvisor';

function AdvisorItem(props) {
  const location = useLocation();
  const { advisorDetailData, buttonTitles, invitedToJoinDate, showBottom, topMatch, displayTopMatchBadge } =
    props;
  const { type } = useAccount();
  const [isSaved, saveAdvisor] = useSavedAdvisor(advisorDetailData.id);
  const [ filledStar, setFilledStar ] = React.useState(
    !!(location.pathname === '/advisors/saved' || isSaved)
  );
  const [ clickedStart, setClickedStar ] = React.useState(false);

  function visitProfile(advisor) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...advisor,
      boardRoomView: type === AccountType.ADVISOR,
    });
  }

  if (invitedToJoinDate) {
    if (buttonTitles) {
      buttonTitles.length = 2;
    }
  }

  React.useEffect(() => {
    if (
      isSaved !== null &&
      (clickedStart || location.pathname !== '/advisors/saved')
    ) {
      setFilledStar(isSaved);
    }
  }, [isSaved]);

  return (
    <AdvisorDetailCard
      {...props}
      advisor={advisorDetailData}
      isStarFilled={filledStar}
      isCardClickable
      showBottom={showBottom}
      cardClick={() => visitProfile(advisorDetailData)}
      starClicked={() => {
        setClickedStar(true);
        saveAdvisor();
      }}
      topMatch={topMatch}
      displayTopMatchBadge={displayTopMatchBadge}
    />
  );
}

export default AdvisorItem;
